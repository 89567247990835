import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import PodImg from '../../../images/pod.jpg'

const paginaServizio = {
  titolo: 'Pod',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: PodImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'Per essere sicuro che il tuo pacco sia stato recapitato correttamente, richiedi POD-Service e accedi comodamente online alla prova di consegna della tua spedizione.',
        },
        {
          id: 2,
          testo:
            'I dati che ti forniamo in tempo reale si riferiscono a ora, data e nominativo di chi ha ritirato la merce insieme alla sua firma digitale. Accedi alla tua area riservata per visualizzare tutte le prove di consegna.',
        },
      ],
      pesoDimensioni: null,
      vantaggi: [
        {
          id: 1,
          testo:
            "Tempi brevissimi: disponibile online all'interno della tua area riservata",
        },
        {
          id: 2,
          testo:
            'Nessun dubbio: le informazioni includono ora, data e nome del destinatario',
        },
        {
          id: 3,
          testo:
            'Tieni monitorate le tue spedizioni direttamente dalla tua area GLS',
        },
        {
          id: 4,
          testo:
            'Prenota il servizio tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
        {
          id: 5,
          testo:
            'Riduci la carta in linea con Think Green, il progetto di sostenibilità ambientale di GLS',
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'National Express',
          linkUrl: '/servizi/national-express',
        },
        {
          id: 2,
          nome: 'Safe Plus',
          linkUrl: '/servizi/safe-plus',
        },
        {
          id: 3,
          nome: 'Parcel',
          linkUrl: '/servizi/parcel',
        },
        {
          id: 4,
          nome: 'Express',
          linkUrl: '/servizi/express',
        },
        {
          id: 5,
          nome: 'Logistics',
          linkUrl: '/servizi/logistics',
        },
      ],
      servizi: null,
    },
  ],
}

const Pod = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default Pod
